import moment from 'moment';
import { getGuestTypeKey, guestTypeKeyByTransactionStatus } from '../../helpers';

import * as settingsHelper from './settingsHelper';
import * as engageHelper from './engageHelper';
import * as settingsReviewChannelsHelper from './settingsReviewChannelsHelper';
import * as settingsAutoResponsesHelper from './settingsAutoResponsesHelper';
import * as settingsWebChatHelper from './settingsWebChatHelper';
import * as settingsDigitalGuideHelper from './settingsDigitalGuideHelper';
import * as settingsGbmHelper from './settingsGbmHelper';
import * as settingsAiModelHelper from './settingsAiModelHelper';
import * as settingsUsersHelper from './settingsUsersHelper';
import * as settingsFeedbacksHelper from './settingsFeedbacksHelper';
import * as settingsReviewsWidgetHelper from './settingsReviewsWidgetHelper';

import * as broadcastHelper from './broadcastHelper';

import * as transactionEmailsHelper from './transactionEmailsHelper';

import * as marketingHelper from './marketingHelper';

import * as reputationHelper from './reputationHelper';

const initialState = {
  locations: {},
};
/* locations: {
  engage: {
    guests: {},
  },
}*/


function sortList(list, keyValue, guestType = '') {
  if (process.env.NODE_ENV !== 'production') {
    console.log('sortList list: ', list);
    console.log('sortList keyValue: ', keyValue);
    console.log('sortList moment: ', moment().format());
  }
  if (guestType === 'upcoming-guest') {
    let guestsForToday = [], guestsForFuture = [], guestsFromPast = [];
    list.forEach((guestId) => {
      const guest = keyValue[guestId];
      if (process.env.NODE_ENV !== 'production') {
        console.log('sortList guest: ', guest._id, ' # check_in_date_local_str', guest.check_in_date_local_str, ' # ', moment(guest.check_in_date_local_str).format());
      }
      let check_in_dt = guest.check_in_date_local_str || guest.check_in_date_str || guest.check_in_date;
      if (moment(check_in_dt).isSame(moment(), 'day')) {
        guestsForToday.push(guestId);
      } else {
        if (moment(check_in_dt) > moment().endOf('day')) {
          guestsForFuture.push(guestId);
        } else {
          guestsFromPast.push(guestId);
        }
      }
    });
    return [...guestsForToday, ...guestsForFuture, ...guestsFromPast];
  }
  const temp_list = list.sort((a, b) => {
    if (!keyValue[a] && keyValue[b]) { return -1; }
    if (keyValue[a] && !keyValue[b]) { return 1; }
    if (!keyValue[a] && !keyValue[b]) { return 0; }
    if (guestType === 'upcoming-guest') {
      const date_a = keyValue[a].check_in_date;
      const date_b = keyValue[b].check_in_date;
      return new Date(date_a).getTime() - new Date(date_b).getTime();
    }
    // let date_a = keyValue[a].date_last_message_received || keyValue[a].date_last_message_sent || keyValue[a].date_last_message;
    // let date_b = keyValue[b].date_last_message_received || keyValue[b].date_last_message_sent || keyValue[b].date_last_message;
    let date_a = keyValue[a].date_last_message || keyValue[a].date_last_message_sent || keyValue[a].date_last_message_received;
    let date_b = keyValue[b].date_last_message || keyValue[b].date_last_message_sent || keyValue[b].date_last_message_received;
    if (!date_a && date_b) { return 1; }
    if (date_a && !date_b) { return -1; }
    // if (!date_a && !date_b) { return 0; }
    if (!date_a && !date_b) {
      date_a = keyValue[a].check_in_date;
      date_b = keyValue[b].check_in_date;
    }
    return new Date(date_b).getTime() - new Date(date_a).getTime();
  });
  return temp_list;
}


const locationReducer = (state = initialState, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('locationReducer action: ', action);
  }
  switch (action.type) {

    case 'USER_LOGOUT': {
      return initialState;
    }

    case "INIT_REPUTATION": {
      return reputationHelper.initReputation(state, action);
    }
    case "INIT_ENGAGE": {
      return engageHelper.initEngage(state, action);
    }
    case "INIT_MARKETING": {
      return marketingHelper.initMarketing(state, action);
    }
    case "INIT_SETTINGS": {
      return settingsHelper.initSettings(state, action);
    }


    // Get activity log
    case "GET_ACTIVITY_LOG_CLEAR": {
      return engageHelper.getActivityLogClear(state, action);
    }
    case "GET_ACTIVITY_LOG": {
      return engageHelper.getActivityLog(state, action);
    }
    case "GET_ACTIVITY_LOG_SUCCESS": {
      return engageHelper.getActivityLogSuccess(state, action);
    }
    case "GET_ACTIVITY_LOG_ERROR": {
      return engageHelper.getActivityLogError(state, action);
    }



    case "GET_SETTINGS": {
      return settingsHelper.getSettings(state, action);
    }
    case "GET_SETTINGS_SUCCESS": {
      return settingsHelper.getSettingsSuccess(state, action);
    }
    case "GET_SETTINGS_ERROR": {
      return settingsHelper.getSettingsError(state, action);
    }


    case "EDIT_SETTINGS_CLEAR": {
      return settingsHelper.editSettingsClear(state, action);
    }
    case "EDIT_SETTINGS": {
      return settingsHelper.editSettings(state, action);
    }
    case "EDIT_SETTINGS_SUCCESS": {
      return settingsHelper.editSettingsSuccess(state, action);
    }
    case "EDIT_SETTINGS_ERROR": {
      return settingsHelper.editSettingsError(state, action);
    }

    /* Get Webchat settings */

    case "GET_WEBCHAT_SETTINGS": {
      return settingsWebChatHelper.getWebChatSettings(state, action);
    }
    case "GET_WEBCHAT_SETTINGS_SUCCESS": {
      return settingsWebChatHelper.getWebChatSettingsSuccess(state, action);
    }
    case "GET_WEBCHAT_SETTINGS_ERROR": {
      return settingsWebChatHelper.getWebChatSettingsError(state, action);
    }

    case "EDIT_WEBCHAT_SETTINGS_CLEAR": {
      return settingsWebChatHelper.editWebChatSettingsClear(state, action);
    }
    case "EDIT_WEBCHAT_SETTINGS": {
      return settingsWebChatHelper.editWebChatSettings(state, action);
    }
    case "EDIT_WEBCHAT_SETTINGS_SUCCESS": {
      return settingsWebChatHelper.editWebChatSettingsSuccess(state, action);
    }
    case "EDIT_WEBCHAT_SETTINGS_ERROR": {
      return settingsWebChatHelper.editWebChatSettingsError(state, action);
    }


    /* Get Webchat Help Guide */

    case "GET_WEBCHAT_HELPGUIDE": {
      return settingsWebChatHelper.getWebChatHelpGuide(state, action);
    }
    case "GET_WEBCHAT_HELPGUIDE_SUCCESS": {
      return settingsWebChatHelper.getWebChatHelpGuideSuccess(state, action);
    }
    case "GET_WEBCHAT_HELPGUIDE_ERROR": {
      return settingsWebChatHelper.getWebChatHelpGuideError(state, action);
    }

    case "EDIT_WEBCHAT_HELPGUIDE_CLEAR": {
      return settingsWebChatHelper.editWebChatHelpGuideClear(state, action);
    }
    case "EDIT_WEBCHAT_HELPGUIDE": {
      return settingsWebChatHelper.editWebChatHelpGuide(state, action);
    }
    case "EDIT_WEBCHAT_HELPGUIDE_SUCCESS": {
      return settingsWebChatHelper.editWebChatHelpGuideSuccess(state, action);
    }
    case "EDIT_WEBCHAT_HELPGUIDE_ERROR": {
      return settingsWebChatHelper.editWebChatHelpGuideError(state, action);
    }


    case "EDIT_WEBCHAT_RULE_CLEAR": {
      return settingsWebChatHelper.editGenericWebChatListItemClear(state, action, 'wc_rule');
    }
    case "EDIT_WEBCHAT_RULE": {
      return settingsWebChatHelper.editGenericWebChatListItem(state, action, 'wc_rule');
    }
    case "EDIT_WEBCHAT_RULE_SUCCESS": {
      return settingsWebChatHelper.editGenericWebChatListItemSuccess(state, action, 'wc_rule');
    }
    case "EDIT_WEBCHAT_RULE_ERROR": {
      return settingsWebChatHelper.editGenericWebChatListItemError(state, action, 'wc_rule');
    }

    /* Get Webchat Rules */

    case "GET_WEBCHAT_RULES": {
      return settingsWebChatHelper.getWebChatRules(state, action);
    }
    case "GET_WEBCHAT_RULES_SUCCESS": {
      return settingsWebChatHelper.getWebChatRulesSuccess(state, action);
    }
    case "GET_WEBCHAT_RULES_ERROR": {
      return settingsWebChatHelper.getWebChatRulesError(state, action);
    }



    /* Get Digital Guide settings */

    case "GET_DIGITAL_GUIDE_SETTINGS": {
      return settingsDigitalGuideHelper.getDigitalGuideSettings(state, action);
    }
    case "GET_DIGITAL_GUIDE_SETTINGS_SUCCESS": {
      return settingsDigitalGuideHelper.getDigitalGuideSettingsSuccess(state, action);
    }
    case "GET_DIGITAL_GUIDE_SETTINGS_ERROR": {
      return settingsDigitalGuideHelper.getDigitalGuideSettingsError(state, action);
    }


    /* Edit Digital Guide page */

    case "EDIT_DIGITAL_GUIDE_PAGE_CLEAR": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemClear(state, action, 'dg_page');
    }
    case "EDIT_DIGITAL_GUIDE_PAGE": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItem(state, action, 'dg_page');
    }
    case "EDIT_DIGITAL_GUIDE_PAGE_SUCCESS": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemSuccess(state, action, 'dg_page');
    }
    case "EDIT_DIGITAL_GUIDE_PAGE_ERROR": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemError(state, action, 'dg_page');
    }

    /* Edit Digital Guide Room */

    case "EDIT_DIGITAL_GUIDE_ROOM_CLEAR": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemClear(state, action, 'dg_room');
    }
    case "EDIT_DIGITAL_GUIDE_ROOM": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItem(state, action, 'dg_room');
    }
    case "EDIT_DIGITAL_GUIDE_ROOM_SUCCESS": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemSuccess(state, action, 'dg_room');
    }
    case "EDIT_DIGITAL_GUIDE_ROOM_ERROR": {
      return settingsDigitalGuideHelper.editGenericDigitalGuideListItemError(state, action, 'dg_room');
    }


    /* Get GBM Settings */

    case "GET_GBM_SETTINGS": {
      return settingsGbmHelper.getGbmSettings(state, action);
    }
    case "GET_GBM_SETTINGS_SUCCESS": {
      return settingsGbmHelper.getGbmSettingsSuccess(state, action);
    }
    case "GET_GBM_SETTINGS_ERROR": {
      return settingsGbmHelper.getGbmSettingsError(state, action);
    }

    case "EDIT_GBM_SETTINGS_CLEAR": {
      return settingsGbmHelper.editGbmSettingsClear(state, action);
    }
    case "EDIT_GBM_SETTINGS": {
      return settingsGbmHelper.editGbmSettings(state, action);
    }
    case "EDIT_GBM_SETTINGS_SUCCESS": {
      return settingsGbmHelper.editGbmSettingsSuccess(state, action);
    }
    case "EDIT_GBM_SETTINGS_ERROR": {
      return settingsGbmHelper.editGbmSettingsError(state, action);
    }


    /* Get Ai Model Data */

    case "GET_AI_MODEL_DATA": {
      return settingsAiModelHelper.getAiModelData(state, action);
    }
    case "GET_AI_MODEL_DATA_SUCCESS": {
      return settingsAiModelHelper.getAiModelDataSuccess(state, action);
    }
    case "GET_AI_MODEL_DATA_ERROR": {
      return settingsAiModelHelper.getAiModelDataError(state, action);
    }


    case "GET_AI_MODEL_RESULT_CLEAR": {
      const { params: { location__id, messages, guest_id, outside_guest_id } } = action.payload;
      if (messages) {
        return settingsAiModelHelper.getAiModelResultClear(state, action);
      }
      if (guest_id || outside_guest_id) {
        return engageHelper.getAiModelResultClear(state, action);
      }
      // return settingsAiModelHelper.getAiModelResultClear(state, action);
    }
    case "GET_AI_MODEL_RESULT": {
      const { params: { location__id, messages, guest_id, outside_guest_id } } = action.payload;
      if (messages) {
        return settingsAiModelHelper.getAiModelResult(state, action);
      }
      if (guest_id || outside_guest_id) {
        return engageHelper.getAiModelResult(state, action);
      }
    }
    case "GET_AI_MODEL_RESULT_SUCCESS": {
      const { params: { location__id, messages, guest_id, outside_guest_id } } = action.payload;
      if (messages) {
        return settingsAiModelHelper.getAiModelResultSuccess(state, action);
      }
      if (guest_id || outside_guest_id) {
        return engageHelper.getAiModelResultSuccess(state, action);
      }
      // return settingsAiModelHelper.getAiModelResultSuccess(state, action);
    }
    case "GET_AI_MODEL_RESULT_ERROR": {
      const { params: { location__id, messages, guest_id, outside_guest_id } } = action.payload;
      if (messages) {
        return settingsAiModelHelper.getAiModelResultError(state, action);
      }
      if (guest_id || outside_guest_id) {
        return engageHelper.getAiModelResultError(state, action);
      }
      // return settingsAiModelHelper.getAiModelResultError(state, action);
    }


    case "EDIT_AI_MODEL_DATA_CLEAR": {
      return settingsAiModelHelper.editAiModelDataClear(state, action);
    }
    case "EDIT_AI_MODEL_DATA": {
      return settingsAiModelHelper.editAiModelData(state, action);
    }
    case "EDIT_AI_MODEL_DATA_SUCCESS": {
      return settingsAiModelHelper.editAiModelDataSuccess(state, action);
    }
    case "EDIT_AI_MODEL_DATA_ERROR": {
      return settingsAiModelHelper.editAiModelDataError(state, action);
    }



    /* Refresh remote lock list */

    case "REFRESH_REMOTE_LOCKS_LIST": {
      return settingsHelper.refreshRemoteLocksList(state, action);
    }
    case "REFRESH_REMOTE_LOCKS_LIST_SUCCESS": {
      return settingsHelper.refreshRemoteLocksListSuccess(state, action);
    }
    case "REFRESH_REMOTE_LOCKS_LIST_ERROR": {
      return settingsHelper.refreshRemoteLocksListError(state, action);
    }


    /* Get Engage Unread Count */

    case "GET_UNREAD_COUNT_CLEAR": {
      return engageHelper.getUnreadCountClear(state, action);
    }
    case "GET_UNREAD_COUNT": {
      return engageHelper.getUnreadCount(state, action);
    }
    case "GET_UNREAD_COUNT_SUCCESS": {
      return engageHelper.getUnreadCountSuccess(state, action);
    }
    case "GET_UNREAD_COUNT_ERROR": {
      return engageHelper.getUnreadCountError(state, action);
    }


    case "GET_GUEST_LIST": {
      const { params: { location__id, type } } = action.payload;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      if (process.env.NODE_ENV !== 'production') {
        console.log('guestTypeKey: ', guestTypeKey);
      }
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                getListInProgress: true, getStatus: {},
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_LIST_SUCCESS": {
      const { data, params: { location__id, type } } = action.payload;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      const { list } = data;
      const guestList = list ? list.map(elem => elem._id) : [];
      const guestListMap = state.locations[location__id] && state.locations[location__id].engage
        && state.locations[location__id].engage[guestTypeKey] && state.locations[location__id].engage[guestTypeKey].keyValue
        ? { ...state.locations[location__id].engage[guestTypeKey].keyValue } : {};
      if (list && list.length) {
        list.forEach((item) => {
          let tempValue = {};
          if (state.locations[location__id] && state.locations[location__id].engage &&
            state.locations[location__id].engage[guestTypeKey] && state.locations[location__id].engage[guestTypeKey].keyValue &&
            state.locations[location__id].engage[guestTypeKey].keyValue[item._id]
          ) {
            // nkcheck - to clear the keyValue
            // tempValue = { ...state.locations[location__id].engage[guestTypeKey].keyValue[item._id] };
            // so as, guest_list doesnt overwrite messages, which is not part of guest_list data
            if (state.locations[location__id].engage[guestTypeKey].keyValue[item._id].messages) {
              tempValue.messages = [ ...state.locations[location__id].engage[guestTypeKey].keyValue[item._id].messages ];
            }
          }
          guestListMap[item._id] = {
            ...tempValue,
            is_removed: false,
            ...item,
          };
        });
        let tempList = [...guestList];
        tempList = sortList(tempList, guestListMap, type);
        tempList = [...new Set(tempList)];
        return {
          ...state,
          locations: {
            ...state.locations,
            [location__id]: {
              ...state.locations[location__id],
              engage: {
                ...state.locations[location__id].engage,
                [guestTypeKey]: {
                  ...state.locations[location__id].engage[guestTypeKey],
                  list: tempList, // guestList,
                  keyValue: guestListMap,
                  getListInProgress: false,
                  getStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        };
      } else {
        return {
          ...state,
          locations: {
            ...state.locations,
            [location__id]: {
              ...state.locations[location__id],
              engage: {
                ...state.locations[location__id].engage,
                [guestTypeKey]: {
                  ...state.locations[location__id].engage[guestTypeKey],
                  list: [],
                  keyValue: {},
                  getListInProgress: false,
                  getStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        };
      }
    }
    case "GET_GUEST_LIST_ERROR": {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { location__id, type } = params;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                getListInProgress: false,
                getStatus: {
                  error: true,
                },
                error,
                errorMessage,
              },
            },
          },
        },
      };
    }

    // Add guest to list
    case "ADD_GUEST_TO_LIST": {
      return engageHelper.addGuestToList(state, action);
    }

    // Get Guest

    case "GET_GUEST": {
      const { params: { location__id, type, guest_id, outside_guest_id } } = action.payload;
      if (!location__id || !type || (!guest_id && !outside_guest_id)) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                keyValue: {
                  ...state.locations[location__id].engage[guestTypeKey].keyValue,
                  [guest_id || outside_guest_id]: {
                    ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                    getInProgress: true,
                    getStatus: {},
                  },
                },
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_SUCCESS": {
      const { data, params: { location__id, type, guest_id, outside_guest_id } } = action.payload;
      if (!location__id || !type || (!guest_id && !outside_guest_id)) return state;
      let guestTypeKey = getGuestTypeKey(type);
      const { guest_data, outside_guest_data } = data;
      let guest_info = {};
      if (guest_data) guest_info = { ...guest_data };
      if (outside_guest_data) guest_info = { ...outside_guest_data };
      let tempList = [ ...state.locations[location__id].engage[guestTypeKey].list ];
      if (!tempList.find(el => el === guest_id || el === outside_guest_id)) {
        tempList = [ guest_id || outside_guest_id, ...tempList ];
      }
      if (guest_info && guest_info.transaction_type) {
        guestTypeKey = guestTypeKeyByTransactionStatus[guest_info.transaction_type];
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('GET_GUEST_SUCCESS guestTypeKey: ', guestTypeKey);
      }
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                list: tempList,
                keyValue: {
                  ...state.locations[location__id].engage[guestTypeKey].keyValue,
                  [guest_id || outside_guest_id]: {
                    ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                    ...guest_info,
                    getInProgress: false,
                    getStatus: {
                      success: true,
                    },
                  }
                },
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_ERROR": {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { location__id, type, guest_id, outside_guest_id } = params;
      if (!location__id || !type || (!guest_id && !outside_guest_id)) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                keyValue: {
                  ...state.locations[location__id].engage[guestTypeKey].keyValue,
                  [guest_id || outside_guest_id]: {
                    ...state.locations[location__id].engage[guestTypeKey].keyValue[guest_id || outside_guest_id],
                    error,
                    errorMessage,
                    getInProgress: false,
                    getStatus: {
                      error: true,
                    },
                  },
                },
              },
            },
          },
        },
      };
    }

    // Get guest search results
    case "GET_GUEST_SEARCH_RESULT_CLEAR": {
      const { params: { location__id, type } } = action.payload;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                searchResults: {
                  contacts: [],
                  resultTypes: {},
                  searchInProgress: false,
                  searchStatus: {},
                },
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_SEARCH_RESULT": {
      const { params: { location__id, type } } = action.payload;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                searchResults: {
                  searchInProgress: true,
                  searchStatus: {},
                },
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_SEARCH_RESULT_SUCCESS": {
      const { data, params: { location__id, type } } = action.payload;
      if (!location__id || !type) return state;
      const { search_list, search_result_types } = data;
      if (!search_list) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                searchResults: {
                  ...state.locations[location__id].engage[guestTypeKey].searchResults,
                  contacts: search_list,
                  resultTypes: search_result_types,
                  searchInProgress: false,
                  searchStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      };
    }
    case "GET_GUEST_SEARCH_RESULT_ERROR": {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { location__id, type } = params;
      if (!location__id || !type) return state;
      const guestTypeKey = getGuestTypeKey(type);
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            engage: {
              ...state.locations[location__id].engage,
              [guestTypeKey]: {
                ...state.locations[location__id].engage[guestTypeKey],
                searchResults: {
                  ...state.locations[location__id].engage[guestTypeKey].searchResults,
                  error,
                  errorMessage,
                  searchInProgress: false,
                  searchStatus: {
                    error: true,
                  },
                },
              },
            },
          },
        },
      };
    }


    // Get new message guest search results
    case "GET_NEW_MESSAGE_SEARCH_RESULT_CLEAR": {
      return engageHelper.getNewMessageSearchResultClear(state, action);
    }
    case "GET_NEW_MESSAGE_SEARCH_RESULT": {
      return engageHelper.getNewMessageSearchResult(state, action);
    }
    case "GET_NEW_MESSAGE_SEARCH_RESULT_SUCCESS": {
      return engageHelper.getNewMessageSearchResultSuccess(state, action);
    }
    case "GET_NEW_MESSAGE_SEARCH_RESULT_ERROR": {
      return engageHelper.getNewMessageSearchResultError(state, action);
    }

    case "ADD_SEARCH_RESULT_GUEST_TO_STORE": {
      return engageHelper.addSearchResultGuestToStore(state, action);
    }


    // case "INIT_EDIT_GUEST": { // nkcheck
    //   return engageHelper.initMessageTemplate(state, action);
    // }

    case "ADD_GUEST_CLEAR": {
      return engageHelper.addGuestClear(state, action);
    }
    case "ADD_GUEST": {
      return engageHelper.addGuest(state, action);
    }
    case "ADD_GUEST_SUCCESS": {
      return engageHelper.addGuestSuccess(state, action);
    }
    case "ADD_GUEST_ERROR": {
      return engageHelper.addGuestError(state, action);
    }


    case "EDIT_GUEST_CLEAR": {
      return engageHelper.editGuestClear(state, action);
    }
    case "EDIT_GUEST": {
      return engageHelper.editGuest(state, action);
    }
    case "EDIT_GUEST_SUCCESS": {
      return engageHelper.editGuestSuccess(state, action);
    }
    case "EDIT_GUEST_ERROR": {
      return engageHelper.editGuestError(state, action);
    }
    case "EDIT_GUEST_REMOVE_ENTRY": {
      return engageHelper.editGuestRemoveEntry(state, action);
    }


    case "INIT_OUTSIDE_GUEST": {
      return engageHelper.initOutsideGuest(state, action);
    }

    case "ADD_OUTSIDE_GUEST_CLEAR": {
      return engageHelper.addOutsideGuestClear(state, action);
    }
    case "ADD_OUTSIDE_GUEST": {
      return engageHelper.addOutsideGuest(state, action);
    }
    case "ADD_OUTSIDE_GUEST_SUCCESS": {
      return engageHelper.addOutsideGuestSuccess(state, action);
    }
    case "ADD_OUTSIDE_GUEST_ERROR": {
      return engageHelper.addOutsideGuestError(state, action);
    }

    case "ADD_OUTSIDE_GUEST_STATUS_CLEAR": {
      return engageHelper.addOutsideGuestStatusClear(state, action);
    }

    case "EDIT_OUTSIDE_GUEST_CLEAR": {
      return engageHelper.editOutsideGuestClear(state, action);
    }
    case "EDIT_OUTSIDE_GUEST": {
      return engageHelper.editOutsideGuest(state, action);
    }
    case "EDIT_OUTSIDE_GUEST_SUCCESS": {
      return engageHelper.editOutsideGuestSuccess(state, action);
    }
    case "EDIT_OUTSIDE_GUEST_ERROR": {
      return engageHelper.editOutsideGuestError(state, action);
    }


    /* Engage - Mark message as read */

    case "MARK_MESSAGE_AS_READ": {
      return engageHelper.markMessageAsRead(state, action);
    }

    /* Handle Pusher message */

    case "HANDLE_ENGAGE_MESSAGE": {
      return engageHelper.handleEngageMessage(state, action);
    }


    /* Send Message - Engage */

    case "SEND_MESSAGE": {
      return engageHelper.sendMessage(state, action);
    }
    case "SEND_MESSAGE_SUCCESS": {
      return engageHelper.sendMessageSuccess(state, action);
    }
    case "SEND_MESSAGE_ERROR": {
      return engageHelper.sendMessageError(state, action);
    }
    case "SEND_MESSAGE_RETRY": {
      return engageHelper.sendMessageRetry(state, action);
    }
    case "SEND_MESSAGE_RETRY_SUCCESS": {
      return engageHelper.sendMessageRetrySuccess(state, action);
    }
    case "SEND_MESSAGE_RETRY_ERROR": {
      return engageHelper.sendMessageRetryError(state, action);
    }



    /* Edit Room */

    case "EDIT_ROOM_CLEAR": {
      return settingsHelper.editGenericSettingsListItemClear(state, action, 'room');
    }
    case "EDIT_ROOM": {
      return settingsHelper.editGenericSettingsListItem(state, action, 'room');
    }
    case "EDIT_ROOM_SUCCESS": {
      return settingsHelper.editGenericSettingsListItemSuccess(state, action, 'room');
    }
    case "EDIT_ROOM_ERROR": {
      return settingsHelper.editGenericSettingsListItemError(state, action, 'room');
    }



    /* Edit Rate Code */

    case "EDIT_RATE_CODE_CLEAR": {
      return settingsHelper.editGenericSettingsListItemClear(state, action, 'rate_code');
    }
    case "EDIT_RATE_CODE": {
      return settingsHelper.editGenericSettingsListItem(state, action, 'rate_code');
    }
    case "EDIT_RATE_CODE_SUCCESS": {
      return settingsHelper.editGenericSettingsListItemSuccess(state, action, 'rate_code');
    }
    case "EDIT_RATE_CODE_ERROR": {
      return settingsHelper.editGenericSettingsListItemError(state, action, 'rate_code');
    }



    /* Edit Booking Source */

    case "EDIT_BOOKING_SOURCE_CLEAR": {
      return settingsHelper.editGenericSettingsListItemClear(state, action, 'booking_source');
    }
    case "EDIT_BOOKING_SOURCE": {
      return settingsHelper.editGenericSettingsListItem(state, action, 'booking_source');
    }
    case "EDIT_BOOKING_SOURCE_SUCCESS": {
      return settingsHelper.editGenericSettingsListItemSuccess(state, action, 'booking_source');
    }
    case "EDIT_BOOKING_SOURCE_ERROR": {
      return settingsHelper.editGenericSettingsListItemError(state, action, 'booking_source');
    }



    /* Edit Sender Name */

    case "EDIT_SENDER_NAME_CLEAR": {
      return settingsHelper.editGenericSettingsMarketingListItemClear(state, action, 'sender_name');
    }
    case "EDIT_SENDER_NAME": {
      return settingsHelper.editGenericSettingsMarketingListItem(state, action, 'sender_name');
    }
    case "EDIT_SENDER_NAME_SUCCESS": {
      return settingsHelper.editGenericSettingsMarketingListItemSuccess(state, action, 'sender_name');
    }
    case "EDIT_SENDER_NAME_ERROR": {
      return settingsHelper.editGenericSettingsMarketingListItemError(state, action, 'sender_name');
    }


    /* Edit Sender Email */

    case "EDIT_SENDER_EMAIL_CLEAR": {
      return settingsHelper.editGenericSettingsMarketingListItemClear(state, action, 'sender_email');
    }
    case "EDIT_SENDER_EMAIL": {
      return settingsHelper.editGenericSettingsMarketingListItem(state, action, 'sender_email');
    }
    case "EDIT_SENDER_EMAIL_SUCCESS": {
      return settingsHelper.editGenericSettingsMarketingListItemSuccess(state, action, 'sender_email');
    }
    case "EDIT_SENDER_EMAIL_ERROR": {
      return settingsHelper.editGenericSettingsMarketingListItemError(state, action, 'sender_email');
    }


    /* Send sender verification email */

    case "SEND_SENDER_EMAIL_VERIFICATION_EMAIL_CLEAR": {
      return settingsHelper.sendSenderEmailVerificationEmailClear(state, action);
    }
    case "SEND_SENDER_EMAIL_VERIFICATION_EMAIL": {
      return settingsHelper.sendSenderEmailVerificationEmail(state, action);
    }
    case "SEND_SENDER_EMAIL_VERIFICATION_EMAIL_SUCCESS": {
      return settingsHelper.sendSenderEmailVerificationEmailSuccess(state, action);
    }
    case "SEND_SENDER_EMAIL_VERIFICATION_EMAIL_ERROR": {
      return settingsHelper.sendSenderEmailVerificationEmailError(state, action);
    }


    /* Get sender email verification status */

    case "GET_SENDER_EMAIL_VERIFICATION_STATUS_CLEAR": {
      return settingsHelper.getSenderEmailVerificationStatusClear(state, action);
    }
    case "GET_SENDER_EMAIL_VERIFICATION_STATUS": {
      return settingsHelper.getSenderEmailVerificationStatus(state, action);
    }
    case "GET_SENDER_EMAIL_VERIFICATION_STATUS_SUCCESS": {
      return settingsHelper.getSenderEmailVerificationStatusSuccess(state, action);
    }
    case "GET_SENDER_EMAIL_VERIFICATION_STATUS_ERROR": {
      return settingsHelper.getSenderEmailVerificationStatusError(state, action);
    }




    case "INIT_MESSAGE_TEMPLATE": {
      return settingsHelper.initMessageTemplate(state, action);
    }
    case "EDIT_MESSAGE_TEMPLATE_CLEAR": {
      return settingsHelper.editGenericEngageListItemClear(state, action, 'messageTemplate');
    }
    case "EDIT_MESSAGE_TEMPLATE": {
      return settingsHelper.editGenericEngageListItem(state, action, 'messageTemplate');
    }
    case "EDIT_MESSAGE_TEMPLATE_SUCCESS": {
      return settingsHelper.editGenericEngageListItemSuccess(state, action, 'messageTemplate');
    }
    case "EDIT_MESSAGE_TEMPLATE_ERROR": {
      return settingsHelper.editGenericEngageListItemError(state, action, 'messageTemplate');
    }

    /* Add Whatsapp message template */

    case "ADD_WHATSAPP_MESSAGE_TEMPLATE_CLEAR": {
      return settingsHelper.addWhatsappMessageTemplateClear(state, action);
    }
    case "ADD_WHATSAPP_MESSAGE_TEMPLATE": {
      return settingsHelper.addWhatsappMessageTemplate(state, action);
    }
    case "ADD_WHATSAPP_MESSAGE_TEMPLATE_SUCCESS": {
      return settingsHelper.addWhatsappMessageTemplateSuccess(state, action);
    }
    case "ADD_WHATSAPP_MESSAGE_TEMPLATE_ERROR": {
      return settingsHelper.addWhatsappMessageTemplateError(state, action);
    }


    /* Edit Engage Guest Label */
    case "EDIT_ENGAGE_GUEST_LABEL_CLEAR": {
      return settingsHelper.editGenericEngageListItemClear(state, action, 'label');
    }
    case "EDIT_ENGAGE_GUEST_LABEL": {
      return settingsHelper.editGenericEngageListItem(state, action, 'label');
    }
    case "EDIT_ENGAGE_GUEST_LABEL_SUCCESS": {
      return settingsHelper.editGenericEngageListItemSuccess(state, action, 'label');
    }
    case "EDIT_ENGAGE_GUEST_LABEL_ERROR": {
      return settingsHelper.editGenericEngageListItemError(state, action, 'label');
    }


    /* Edit Guest journey rule */

    case "EDIT_GUEST_JOURNEY_RULE_CLEAR": {
      return settingsHelper.editGuestJourneyRuleClear(state, action);
    }
    case "EDIT_GUEST_JOURNEY_RULE": {
      return settingsHelper.editGuestJourneyRule(state, action);
    }
    case "EDIT_GUEST_JOURNEY_RULE_SUCCESS": {
      return settingsHelper.editGuestJourneyRuleSuccess(state, action);
    }
    case "EDIT_GUEST_JOURNEY_RULE_ERROR": {
      return settingsHelper.editGuestJourneyRuleError(state, action);
    }



    /* Edit Review Channels */

    case "EDIT_REVIEW_CHANNELS_CLEAR": {
      return settingsReviewChannelsHelper.editSettingsReviewChannelsClear(state, action);
    }
    case "EDIT_REVIEW_CHANNELS": {
      return settingsReviewChannelsHelper.editSettingsReviewChannels(state, action);
    }
    case "EDIT_REVIEW_CHANNELS_SUCCESS": {
      return settingsReviewChannelsHelper.editSettingsReviewChannelsSuccess(state, action);
    }
    case "EDIT_REVIEW_CHANNELS_ERROR": {
      return settingsReviewChannelsHelper.editSettingsReviewChannelsError(state, action);
    }


    /* Edit Reputation Auto Responses */

    case "EDIT_REPUTATION_AUTO_RESPONSES_CLEAR": {
      return settingsAutoResponsesHelper.editReputationAutoResponsesClear(state, action);
    }
    case "EDIT_REPUTATION_AUTO_RESPONSES": {
      return settingsAutoResponsesHelper.editReputationAutoResponses(state, action);
    }
    case "EDIT_REPUTATION_AUTO_RESPONSES_SUCCESS": {
      return settingsAutoResponsesHelper.editReputationAutoResponsesSuccess(state, action);
    }
    case "EDIT_REPUTATION_AUTO_RESPONSES_ERROR": {
      return settingsAutoResponsesHelper.editReputationAutoResponsesError(state, action);
    }

    /* Edit Response Templates */

    case "EDIT_RESPONSE_TEMPLATE_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'response_template');
    }
    case "EDIT_RESPONSE_TEMPLATE": {
      return settingsHelper.editGenericReputationListItem(state, action, 'response_template');
    }
    case "EDIT_RESPONSE_TEMPLATE_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'response_template');
    }
    case "EDIT_RESPONSE_TEMPLATE_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'response_template');
    }


    /* Edit Response Template Category */

    case "EDIT_RESPONSE_TEMPLATE_CATEGORY_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'response_template_category');
    }
    case "EDIT_RESPONSE_TEMPLATE_CATEGORY": {
      return settingsHelper.editGenericReputationListItem(state, action, 'response_template_category');
    }
    case "EDIT_RESPONSE_TEMPLATE_CATEGORY_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'response_template_category');
    }
    case "EDIT_RESPONSE_TEMPLATE_CATEGORY_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'response_template_category');
    }


    /* Edit Prompt Templates */

    case "EDIT_PROMPT_TEMPLATE_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'prompt_template');
    }
    case "EDIT_PROMPT_TEMPLATE": {
      return settingsHelper.editGenericReputationListItem(state, action, 'prompt_template');
    }
    case "EDIT_PROMPT_TEMPLATE_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'prompt_template');
    }
    case "EDIT_PROMPT_TEMPLATE_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'prompt_template');
    }


    /* Edit Prompt Template Category */

    case "EDIT_PROMPT_TEMPLATE_CATEGORY_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'prompt_template_category');
    }
    case "EDIT_PROMPT_TEMPLATE_CATEGORY": {
      return settingsHelper.editGenericReputationListItem(state, action, 'prompt_template_category');
    }
    case "EDIT_PROMPT_TEMPLATE_CATEGORY_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'prompt_template_category');
    }
    case "EDIT_PROMPT_TEMPLATE_CATEGORY_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'prompt_template_category');
    }

    /* Edit Feedback Text Templates */

    case "EDIT_FEEDBACK_TEXT_TEMPLATE_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'feedback_text_template');
    }
    case "EDIT_FEEDBACK_TEXT_TEMPLATE": {
      return settingsHelper.editGenericReputationListItem(state, action, 'feedback_text_template');
    }
    case "EDIT_FEEDBACK_TEXT_TEMPLATE_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'feedback_text_template');
    }
    case "EDIT_FEEDBACK_TEXT_TEMPLATE_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'feedback_text_template');
    }


     /* Edit Comp Set Locations */

     case "EDIT_COMP_SET_LOCATION_CLEAR": {
      return settingsHelper.editGenericReputationListItemClear(state, action, 'comp_set_location');
    }
    case "EDIT_COMP_SET_LOCATION": {
      return settingsHelper.editGenericReputationListItem(state, action, 'comp_set_location');
    }
    case "EDIT_COMP_SET_LOCATION_SUCCESS": {
      return settingsHelper.editGenericReputationListItemSuccess(state, action, 'comp_set_location');
    }
    case "EDIT_COMP_SET_LOCATION_ERROR": {
      return settingsHelper.editGenericReputationListItemError(state, action, 'comp_set_location');
    }



    /* Get users for location */

    case "GET_USERS_FOR_LOCATION_CLEAR": {
      return settingsUsersHelper.getUsersForLocation(state, action);
    }
    case "GET_USERS_FOR_LOCATION": {
      return settingsUsersHelper.getUsersForLocation(state, action);
    }
    case "GET_USERS_FOR_LOCATION_SUCCESS": {
      return settingsUsersHelper.getUsersForLocationSuccess(state, action);
    }
    case "GET_USERS_FOR_LOCATION_ERROR": {
      return settingsUsersHelper.getUsersForLocationError(state, action);
    }

    case "EDIT_USER_FOR_LOCATION_CLEAR": {
      return settingsUsersHelper.editUserForLocationClear(state, action);
    }
    case "EDIT_USER_FOR_LOCATION": {
      return settingsUsersHelper.editUserForLocation(state, action);
    }
    case "EDIT_USER_FOR_LOCATION_SUCCESS": {
      return settingsUsersHelper.editUserForLocationSuccess(state, action);
    }
    case "EDIT_USER_FOR_LOCATION_ERROR": {
      return settingsUsersHelper.editUserForLocationError(state, action);
    }

    case "EDIT_USER_RESET_PASSWORD_CLEAR": {
      return settingsUsersHelper.editUserResetPasswordClear(state, action);
    }
    case "EDIT_USER_RESET_PASSWORD": {
      return settingsUsersHelper.editUserResetPassword(state, action);
    }
    case "EDIT_USER_RESET_PASSWORD_SUCCESS": {
      return settingsUsersHelper.editUserResetPasswordSuccess(state, action);
    }
    case "EDIT_USER_RESET_PASSWORD_ERROR": {
      return settingsUsersHelper.editUserResetPasswordError(state, action);
    }


    /* Get Broadcasts */

    case "GET_BROADCASTS": {
      return broadcastHelper.getBroadcasts(state, action);
    }
    case "GET_BROADCASTS_SUCCESS": {
      return broadcastHelper.getBroadcastsSuccess(state, action);
    }
    case "GET_BROADCASTS_ERROR": {
      return broadcastHelper.getBroadcastsError(state, action);
    }

    /* Edit Broadcast */

    case "EDIT_BROADCAST_CLEAR": {
      return broadcastHelper.editBroadcastClear(state, action);
    }
    case "EDIT_BROADCAST": {
      return broadcastHelper.editBroadcast(state, action);
    }
    case "EDIT_BROADCAST_SUCCESS": {
      return broadcastHelper.editBroadcastSuccess(state, action);
    }
    case "EDIT_BROADCAST_ERROR": {
      return broadcastHelper.editBroadcastError(state, action);
    }

    /* Get Broadcasts History */

    case "GET_BROADCASTS_HISTORY": {
      return broadcastHelper.getBroadcastsHistory(state, action);
    }
    case "GET_BROADCASTS_HISTORY_SUCCESS": {
      return broadcastHelper.getBroadcastsHistorySuccess(state, action);
    }
    case "GET_BROADCASTS_HISTORY_ERROR": {
      return broadcastHelper.getBroadcastsHistoryError(state, action);
    }

    /* Get Broadcast Run Detail */

    case "GET_BROADCAST_RUN_DETAIL": {
      return broadcastHelper.getBroadcastRunDetail(state, action);
    }
    case "GET_BROADCAST_RUN_DETAIL_SUCCESS": {
      return broadcastHelper.getBroadcastRunDetailSuccess(state, action);
    }
    case "GET_BROADCAST_RUN_DETAIL_ERROR": {
      return broadcastHelper.getBroadcastRunDetailError(state, action);
    }


    /* Get engage usage */

    case "GET_ENGAGE_USAGE_CLEAR": {
      return engageHelper.getEngageUsageClear(state, action);
    }
    case "GET_ENGAGE_USAGE": {
      return engageHelper.getEngageUsage(state, action);
    }
    case "GET_ENGAGE_USAGE_SUCCESS": {
      return engageHelper.getEngageUsageSuccess(state, action);
    }
    case "GET_ENGAGE_USAGE_ERROR": {
      return engageHelper.getEngageUsageError(state, action);
    }


    /* Transaction Email Stats */

    case "GET_TRANSACTION_EMAIL_STATS": {
      return transactionEmailsHelper.getTransactionEmailStats(state, action);
    }
    case "GET_TRANSACTION_EMAIL_STATS_SUCCESS": {
      return transactionEmailsHelper.getTransactionEmailStatsSuccess(state, action);
    }
    case "GET_TRANSACTION_EMAIL_STATS_ERROR": {
      return transactionEmailsHelper.getTransactionEmailStatsError(state, action);
    }


    /* Get Transaction Emails */

    case "GET_TRANSACTION_EMAILS": {
      return transactionEmailsHelper.getTransactionEmails(state, action);
    }
    case "GET_TRANSACTION_EMAILS_SUCCESS": {
      return transactionEmailsHelper.getTransactionEmailsSuccess(state, action);
    }
    case "GET_TRANSACTION_EMAILS_ERROR": {
      return transactionEmailsHelper.getTransactionEmailsError(state, action);
    }

    /* Edit Transaction Emails */

    case "EDIT_TRANSACTION_EMAIL_CLEAR": {
      return transactionEmailsHelper.editGenericTEListItemClear(state, action, 'transaction_email');
    }
    case "EDIT_TRANSACTION_EMAIL": {
      return transactionEmailsHelper.editGenericTEListItem(state, action, 'transaction_email');
    }
    case "EDIT_TRANSACTION_EMAIL_SUCCESS": {
      return transactionEmailsHelper.editGenericTEListItemSuccess(state, action, 'transaction_email');
    }
    case "EDIT_TRANSACTION_EMAIL_ERROR": {
      return transactionEmailsHelper.editGenericTEListItemError(state, action, 'transaction_email');
    }

    /* Edit Email Template */

    case "EDIT_EMAIL_TEMPLATE_CLEAR": {
      return transactionEmailsHelper.editGenericTEListItemClear(state, action, 'email_template');
    }
    case "EDIT_EMAIL_TEMPLATE": {
      return transactionEmailsHelper.editGenericTEListItem(state, action, 'email_template');
    }
    case "EDIT_EMAIL_TEMPLATE_SUCCESS": {
      return transactionEmailsHelper.editGenericTEListItemSuccess(state, action, 'email_template');
    }
    case "EDIT_EMAIL_TEMPLATE_ERROR": {
      return transactionEmailsHelper.editGenericTEListItemError(state, action, 'email_template');
    }

    /* Email Template Send test Email */

    case "EMAIL_TEMPLATE_SEND_TEST_EMAIL_CLEAR": {
      return transactionEmailsHelper.emailTemplateSendTestEmailClear(state, action);
    }
    case "EMAIL_TEMPLATE_SEND_TEST_EMAIL": {
      return transactionEmailsHelper.emailTemplateSendTestEmail(state, action);
    }
    case "EMAIL_TEMPLATE_SEND_TEST_EMAIL_SUCCESS": {
      return transactionEmailsHelper.emailTemplateSendTestEmailSuccess(state, action);
    }
    case "EMAIL_TEMPLATE_SEND_TEST_EMAIL_ERROR": {
      return transactionEmailsHelper.emailTemplateSendTestEmailError(state, action);
    }


    /* Get Response For Feedback */

    case "GET_RESPONSE_FOR_FEEDBACK_CLEAR": {
      return reputationHelper.getResponseForFeedbackClear(state, action);
    }
    case "GET_RESPONSE_FOR_FEEDBACK": {
      return reputationHelper.getResponseForFeedback(state, action);
    }
    case "GET_RESPONSE_FOR_FEEDBACK_SUCCESS": {
      return reputationHelper.getResponseForFeedbackSuccess(state, action);
    }
    case "GET_RESPONSE_FOR_FEEDBACK_ERROR": {
      return reputationHelper.getResponseForFeedbackError(state, action);
    }


    /* Send Custom Feedback Reply */

    case "SEND_CUSTOM_FEEDBACK_REPLY_CLEAR": {
      return reputationHelper.sendCustomFeedbackReplyClear(state, action);
    }
    case "SEND_CUSTOM_FEEDBACK_REPLY": {
      return reputationHelper.sendCustomFeedbackReply(state, action);
    }
    case "SEND_CUSTOM_FEEDBACK_REPLY_SUCCESS": {
      return reputationHelper.sendCustomFeedbackReplySuccess(state, action);
    }
    case "SEND_CUSTOM_FEEDBACK_REPLY_ERROR": {
      return reputationHelper.sendCustomFeedbackReplyError(state, action);
    }


    /* Get Transaction Emails History */

    case "GET_TRANSACTION_EMAILS_HISTORY": {
      return transactionEmailsHelper.getTransactionEmailsHistory(state, action);
    }
    case "GET_TRANSACTION_EMAILS_HISTORY_SUCCESS": {
      return transactionEmailsHelper.getTransactionEmailsHistorySuccess(state, action);
    }
    case "GET_TRANSACTION_EMAILS_HISTORY_ERROR": {
      return transactionEmailsHelper.getTransactionEmailsHistoryError(state, action);
    }


    /* Resend Transaction Email */

    case "RESEND_TRANSACTION_EMAIL_CLEAR": {
      return transactionEmailsHelper.resendTransactionEmailClear(state, action);
    }
    case "RESEND_TRANSACTION_EMAIL": {
      return transactionEmailsHelper.resendTransactionEmail(state, action);
    }
    case "RESEND_TRANSACTION_EMAIL_SUCCESS": {
      return transactionEmailsHelper.resendTransactionEmailSuccess(state, action);
    }
    case "RESEND_TRANSACTION_EMAIL_ERROR": {
      return transactionEmailsHelper.resendTransactionEmailError(state, action);
    }


    /* Send  Custom Transaction Email */

    case "SEND_CUSTOM_TRANSACTION_EMAIL_CLEAR": {
      return transactionEmailsHelper.sendCustomTransactionEmailClear(state, action);
    }
    case "SEND_CUSTOM_TRANSACTION_EMAIL": {
      return transactionEmailsHelper.sendCustomTransactionEmail(state, action);
    }
    case "SEND_CUSTOM_TRANSACTION_EMAIL_SUCCESS": {
      return transactionEmailsHelper.sendCustomTransactionEmailSuccess(state, action);
    }
    case "SEND_CUSTOM_TRANSACTION_EMAIL_ERROR": {
      return transactionEmailsHelper.sendCustomTransactionEmailError(state, action);
    }


    /* Get Transaction Emails Reservations */

    case "GET_TRANSACTION_EMAILS_RESERVATIONS": {
      return transactionEmailsHelper.getTransactionEmailsReservations(state, action);
    }
    case "GET_TRANSACTION_EMAILS_RESERVATIONS_SUCCESS": {
      return transactionEmailsHelper.getTransactionEmailsReservationsSuccess(state, action);
    }
    case "GET_TRANSACTION_EMAILS_RESERVATIONS_ERROR": {
      return transactionEmailsHelper.getTransactionEmailsReservationsError(state, action);
    }


    /* Reputation */

    /* Get Reputation Dashboard Data */

    case "GET_DASHBOARD_DATA": {
      return reputationHelper.getDashboardData(state, action);
    }
    case "GET_DASHBOARD_DATA_SUCCESS": {
      return reputationHelper.getDashboardDataSuccess(state, action);
    }
    case "GET_DASHBOARD_DATA_ERROR": {
      return reputationHelper.getDashboardDataError(state, action);
    }

    /* Add Dashboard Review to Reviews  */

    case "ADD_REVIEW_TO_REVIEWS": {
      return reputationHelper.addReviewToReviews(state, action);
    }


    /* Get Reputation Reviews Data */

    case "GET_REVIEWS_DATA": {
      return reputationHelper.getReviewsData(state, action);
    }
    case "GET_REVIEWS_DATA_SUCCESS": {
      return reputationHelper.getReviewsDataSuccess(state, action);
    }
    case "GET_REVIEWS_DATA_ERROR": {
      return reputationHelper.getReviewsDataError(state, action);
    }

    /* Get Reputation Reviews Dump */

    case "GET_REVIEWS_DUMP": {
      return reputationHelper.getReviewsDump(state, action);
    }
    case "GET_REVIEWS_DUMP_SUCCESS": {
      return reputationHelper.getReviewsDumpSuccess(state, action);
    }
    case "GET_REVIEWS_DUMP_ERROR": {
      return reputationHelper.getReviewsDumpError(state, action);
    }


    /* Get Reputation Comp Data */

    case "GET_COMP_DATA": {
      return reputationHelper.getCompData(state, action);
    }
    case "GET_COMP_DATA_SUCCESS": {
      return reputationHelper.getCompDataSuccess(state, action);
    }
    case "GET_COMP_DATA_ERROR": {
      return reputationHelper.getCompDataError(state, action);
    }


    /* Get Reputation Sentiments Data */

    case "GET_SENTIMENTS_DATA": {
      return reputationHelper.getSentimentsData(state, action);
    }
    case "GET_SENTIMENTS_DATA_SUCCESS": {
      return reputationHelper.getSentimentsDataSuccess(state, action);
    }
    case "GET_SENTIMENTS_DATA_ERROR": {
      return reputationHelper.getSentimentsDataError(state, action);
    }


    /* Get Feedback Overview Data */

    case "GET_FEEDBACK_OVERVIEW_DATA": {
      return reputationHelper.getFeedbackOverviewData(state, action);
    }
    case "GET_FEEDBACK_OVERVIEW_DATA_SUCCESS": {
      return reputationHelper.getFeedbackOverviewDataSuccess(state, action);
    }
    case "GET_FEEDBACK_OVERVIEW_DATA_ERROR": {
      return reputationHelper.getFeedbackOverviewDataError(state, action);
    }


    /* Get Feedback Overview Dump */

    case "GET_FEEDBACK_OVERVIEW_DUMP": {
      return reputationHelper.getFeedbackOverviewDump(state, action);
    }
    case "GET_FEEDBACK_OVERVIEW_DUMP_SUCCESS": {
      return reputationHelper.getFeedbackOverviewDumpSuccess(state, action);
    }
    case "GET_FEEDBACK_OVERVIEW_DUMP_ERROR": {
      return reputationHelper.getFeedbackOverviewDumpError(state, action);
    }


    /* Get Feedback Request Sent Data */

    case "GET_FEEDBACK_SENT_DATA": {
      return reputationHelper.getFeedbackSentData(state, action);
    }
    case "GET_FEEDBACK_SENT_DATA_SUCCESS": {
      return reputationHelper.getFeedbackSentDataSuccess(state, action);
    }
    case "GET_FEEDBACK_SENT_DATA_ERROR": {
      return reputationHelper.getFeedbackSentDataError(state, action);
    }


    /* Get Feedback Request Sent Dump */

    case "GET_FEEDBACK_SENT_DUMP": {
      return reputationHelper.getFeedbackSentDump(state, action);
    }
    case "GET_FEEDBACK_SENT_DUMP_SUCCESS": {
      return reputationHelper.getFeedbackSentDumpSuccess(state, action);
    }
    case "GET_FEEDBACK_SENT_DUMP_ERROR": {
      return reputationHelper.getFeedbackSentDumpError(state, action);
    }


    /* Get Feedback Upload History Data */

    case "GET_FEEDBACK_UPLOAD_HISTORY_DATA": {
      return reputationHelper.getFeedbackUploadHistoryData(state, action);
    }
    case "GET_FEEDBACK_UPLOAD_HISTORY_DATA_SUCCESS": {
      return reputationHelper.getFeedbackUploadHistoryDataSuccess(state, action);
    }
    case "GET_FEEDBACK_UPLOAD_HISTORY_DATA_ERROR": {
      return reputationHelper.getFeedbackUploadHistoryDataError(state, action);
    }


    /* Upload Feedback Emails */

    case "UPLOAD_FEEDBACK_EMAILS_CLEAR": {
      return reputationHelper.uploadFeedbackEmailsClear(state, action);
    }
    case "UPLOAD_FEEDBACK_EMAILS": {
      return reputationHelper.uploadFeedbackEmails(state, action);
    }
    case "UPLOAD_FEEDBACK_EMAILS_SUCCESS": {
      return reputationHelper.uploadFeedbackEmailsSuccess(state, action);
    }
    case "UPLOAD_FEEDBACK_EMAILS_ERROR": {
      return reputationHelper.uploadFeedbackEmailsError(state, action);
    }

    /* Edit Feedback Settings */

    case "EDIT_FEEDBACK_SETTINGS_CLEAR": {
      return settingsFeedbacksHelper.editFeedbackSettingsClear(state, action);
    }
    case "EDIT_FEEDBACK_SETTINGS": {
      return settingsFeedbacksHelper.editFeedbackSettings(state, action);
    }
    case "EDIT_FEEDBACK_SETTINGS_SUCCESS": {
      return settingsFeedbacksHelper.editFeedbackSettingsSuccess(state, action);
    }
    case "EDIT_FEEDBACK_SETTINGS_ERROR": {
      return settingsFeedbacksHelper.editFeedbackSettingsError(state, action);
    }

    /* Send Test Email Custom Feedback */

    case "SEND_TEST_EMAIL_CUSTOM_FEEDBACK_CLEAR": {
      return settingsFeedbacksHelper.sendTestEmailCustomFeedbackClear(state, action);
    }
    case "SEND_TEST_EMAIL_CUSTOM_FEEDBACK": {
      return settingsFeedbacksHelper.sendTestEmailCustomFeedback(state, action);
    }
    case "SEND_TEST_EMAIL_CUSTOM_FEEDBACK_SUCCESS": {
      return settingsFeedbacksHelper.sendTestEmailCustomFeedbackSuccess(state, action);
    }
    case "SEND_TEST_EMAIL_CUSTOM_FEEDBACK_ERROR": {
      return settingsFeedbacksHelper.sendTestEmailCustomFeedbackError(state, action);
    }




    /* Get GuestList Overview Data */

    case "GET_GUESTLIST_OVERVIEW_DATA": {
      return reputationHelper.getGuestListOverviewData(state, action);
    }
    case "GET_GUESTLIST_OVERVIEW_DATA_SUCCESS": {
      return reputationHelper.getGuestListOverviewDataSuccess(state, action);
    }
    case "GET_GUESTLIST_OVERVIEW_DATA_ERROR": {
      return reputationHelper.getGuestListOverviewDataError(state, action);
    }

    /* Get GuestList Data Dump */

    case "GET_GUESTLIST_DUMP": {
      return reputationHelper.getGuestListDump(state, action);
    }
    case "GET_GUESTLIST_DUMP_SUCCESS": {
      return reputationHelper.getGuestListDumpSuccess(state, action);
    }
    case "GET_GUESTLIST_DUMP_ERROR": {
      return reputationHelper.getGuestListDumpError(state, action);
    }

    /* Get Reputation Instant invite Dashboard Data */

    case "GET_INSTANT_INVITE_DASHBOARD_DATA": {
      return reputationHelper.getInstantInviteDashboardData(state, action);
    }
    case "GET_INSTANT_INVITE_DASHBOARD_DATA_SUCCESS": {
      return reputationHelper.getInstantInviteDashboardDataSuccess(state, action);
    }
    case "GET_INSTANT_INVITE_DASHBOARD_DATA_ERROR": {
      return reputationHelper.getInstantInviteDashboardDataError(state, action);
    }


    /* Edit Review */

    case "EDIT_REVIEW_CLEAR": {
      return reputationHelper.editReviewClear(state, action);
    }
    case "EDIT_REVIEW": {
      return reputationHelper.editReview(state, action);
    }
    case "EDIT_REVIEW_SUCCESS": {
      return reputationHelper.editReviewSuccess(state, action);
    }
    case "EDIT_REVIEW_ERROR": {
      return reputationHelper.editReviewError(state, action);
    }


    /* Update Review Translation */

    case "UPDATE_REVIEW_TRANSLATION_CLEAR": {
      return reputationHelper.updateReviewTranslationClear(state, action);
    }
    case "UPDATE_REVIEW_TRANSLATION": {
      return reputationHelper.updateReviewTranslation(state, action);
    }
    case "UPDATE_REVIEW_TRANSLATION_SUCCESS": {
      return reputationHelper.updateReviewTranslationSuccess(state, action);
    }
    case "UPDATE_REVIEW_TRANSLATION_ERROR": {
      return reputationHelper.updateReviewTranslationError(state, action);
    }



    /* Send Instant Invite Feedback Request */

    case "SEND_INSTANT_INVITE_FEEDBACK_REQUEST_CLEAR": {
      return reputationHelper.sendInstantInviteFeedbackRequestClear(state, action);
    }
    case "SEND_INSTANT_INVITE_FEEDBACK_REQUEST": {
      return reputationHelper.sendInstantInviteFeedbackRequest(state, action);
    }
    case "SEND_INSTANT_INVITE_FEEDBACK_REQUEST_SUCCESS": {
      return reputationHelper.sendInstantInviteFeedbackRequestSuccess(state, action);
    }
    case "SEND_INSTANT_INVITE_FEEDBACK_REQUEST_ERROR": {
      return reputationHelper.sendInstantInviteFeedbackRequestError(state, action);
    }


    /* Get Response */

    case "GET_RESPONSE_CLEAR": {
      return reputationHelper.getResponseClear(state, action);
    }
    case "GET_RESPONSE": {
      return reputationHelper.getResponse(state, action);
    }
    case "GET_RESPONSE_SUCCESS": {
      return reputationHelper.getResponseSuccess(state, action);
    }
    case "GET_RESPONSE_ERROR": {
      return reputationHelper.getResponseError(state, action);
    }


    /* Post response on google */

    case 'POST_RESPONSE_ON_GOOGLE_CLEAR': {
      return reputationHelper.postResponseOnGoogleClear(state, action);
    }
    case 'POST_RESPONSE_ON_GOOGLE': {
      return reputationHelper.postResponseOnGoogle(state, action);
    }
    case 'POST_RESPONSE_ON_GOOGLE_SUCCESS': {
      return reputationHelper.postResponseOnGoogleSuccess(state, action);
    }
    case 'POST_RESPONSE_ON_GOOGLE_ERROR': {
      return reputationHelper.postResponseOnGoogleError(state, action);
    }


    /* Get Reviews Widget settings */

    case "GET_REVIEWS_WIDGET_SETTINGS": {
      return settingsReviewsWidgetHelper.getReviewsWidgetSettings(state, action);
    }
    case "GET_REVIEWS_WIDGET_SETTINGS_SUCCESS": {
      return settingsReviewsWidgetHelper.getReviewsWidgetSettingsSuccess(state, action);
    }
    case "GET_REVIEWS_WIDGET_SETTINGS_ERROR": {
      return settingsReviewsWidgetHelper.getReviewsWidgetSettingsError(state, action);
    }


    /* Edit Reviews Widget Settings */

    case "EDIT_REVIEWS_WIDGET_SETTINGS_CLEAR": {
      return settingsReviewsWidgetHelper.editReviewsWidgetSettingsClear(state, action);
    }
    case "EDIT_REVIEWS_WIDGET_SETTINGS": {
      return settingsReviewsWidgetHelper.editReviewsWidgetSettings(state, action);
    }
    case "EDIT_REVIEWS_WIDGET_SETTINGS_SUCCESS": {
      return settingsReviewsWidgetHelper.editReviewsWidgetSettingsSuccess(state, action);
    }
    case "EDIT_REVIEWS_WIDGET_SETTINGS_ERROR": {
      return settingsReviewsWidgetHelper.editReviewsWidgetSettingsError(state, action);
    }

    /* Get Reviews Widget Rules */

    case "GET_REVIEWS_WIDGET_RULES": {
      return settingsReviewsWidgetHelper.getReviewsWidgetRules(state, action);
    }
    case "GET_REVIEWS_WIDGET_RULES_SUCCESS": {
      return settingsReviewsWidgetHelper.getReviewsWidgetRulesSuccess(state, action);
    }
    case "GET_REVIEWS_WIDGET_RULES_ERROR": {
      return settingsReviewsWidgetHelper.getReviewsWidgetRulesError(state, action);
    }

    /* Edit Reviews Widget Rule */

    case "EDIT_REVIEWS_WIDGET_RULE_CLEAR": {
      return settingsReviewsWidgetHelper.editGenericReviewsWidgetListItemClear(state, action, 'rw_rule');
    }
    case "EDIT_REVIEWS_WIDGET_RULE": {
      return settingsReviewsWidgetHelper.editGenericReviewsWidgetListItem(state, action, 'rw_rule');
    }
    case "EDIT_REVIEWS_WIDGET_RULE_SUCCESS": {
      return settingsReviewsWidgetHelper.editGenericReviewsWidgetListItemSuccess(state, action, 'rw_rule');
    }
    case "EDIT_REVIEWS_WIDGET_RULE_ERROR": {
      return settingsReviewsWidgetHelper.editGenericReviewsWidgetListItemError(state, action, 'rw_rule');
    }


  }
  return state;
};
export default locationReducer;
